import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
// import { useStorage } from '@vueuse/core';

import {
  USER_API,
  LAUNCH_DARKLY_CLIENT_ID,
  BASE_DOMAIN,
} from '@/config/appConfig';

import * as LaunchDarkly from '@tagboard/launchdarkly';

import usePlansService from '@/services/plans';
import { Permissions } from '@/utils/constants';

import useAuthStore from '@/stores/auth';

const plansService = usePlansService();
const isLoadingUser = ref(true);

export default defineStore('user', () => {
  const authStore = useAuthStore();

  const {
    currentTeamId,
    userId,
    isAuthenticated,
    isStaff,
    userToken,
    isDesigner, // Group for peeps who can manage presets
    profile,
    accounts,
    facebookPages,
    user,
  } = storeToRefs(authStore);

  const {
    getAuth,
  } = authStore;

  const {
    data: teamsData,
    execute: getTeams,
  } = plansService.getTeams(userId, { immediate: false });

  const teamsById = computed(() => teamsData.value?.owners ?? {});
  // const teams = computed(() => Object.values(teamsById.value));

  const {
    data: planData,
    execute: getPlan,
  } = plansService.getPlan({ immediate: false });

  const hasPlan = computed(() => planData.value?.plan !== 'free');
  const isFreeAccountNoTeam = computed(() => planData.value?.plan === 'free' && !Object.keys(teamsById.value).length);

  const {
    data: planPermissionData,
    execute: checkPlanPermissions,
    isFetching: isFetchingPermissions,
  } = plansService
    .checkPlanPermissions(
      currentTeamId,
      Permissions,
      { immediate: false, refetch: true, initialData: {} },
    );

  const {
    data: teamMembersData,
    execute: getTeamMembers,
    isFetching: isFetchingTeamMembers,
  } = plansService
    .getTeamMembers(currentTeamId, { immediate: false, refetch: true });

  const teamMembersById = computed(() => teamMembersData.value?.admins ?? {});
  const teamMembersMeta = computed(() => teamMembersData.value?.meta ?? {});

  const {
    data: invitesData,
    isFetching: isFetchingInvites,
    execute: fetchInvites,
  } = plansService
    .getTeamInvites(userId, { immediate: false });

  const currentTeam = computed(() => {
    if (teamsById.value[currentTeamId.value]) {
      const teamData = teamsById.value[currentTeamId.value];
      // patch bad data from API
      if (teamData.facebook_pages) {
        teamData.facebook_pages = Object.values(teamData.facebook_pages);
      }

      return teamData;
    }

    return {
      ...profile.value,
      accounts: accounts.value,
      facebook_pages: facebookPages.value,
    };
  });

  function setCurrentTeamId(teamId) {
    currentTeamId.value = Number(teamId);
  }

  async function loadUser() {
    isLoadingUser.value = true;
    await getAuth(true);

    let useStoredTeamId = true;
    // New login (no team saved in local storage)
    // Set the current team to the user's id for now (we block requests until we have a team id)
    if (!currentTeamId.value) {
      setCurrentTeamId(userId.value);
      useStoredTeamId = false;
    }

    await Promise.all([
      getTeams(true),
      getPlan(true),
    ]);

    // If there isn't a saved team figure out which one to use
    if (!useStoredTeamId) {
      if (hasPlan.value || isFreeAccountNoTeam.value) {
        setCurrentTeamId(userId.value);
      } else {
        // else free account with a team(s)
        setCurrentTeamId(Object.keys(teamsById.value)[0]);
      }
    } else {
      /**
       * getTeamMembers and checkPlanPermissions are called automatically via refetch
       * when currentTeamId changes (above) so we call it manually if the team was already
       * set (i.e. page refresh)
       */
      getTeamMembers();
      checkPlanPermissions();
    }

    await fetchInvites(true);

    // Configure LaunchDarkly with signed-in user
    try {
      LaunchDarkly.init({
        userApi: USER_API,
        clientId: LAUNCH_DARKLY_CLIENT_ID,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to initialize LaunchDarkly:', err);
      isLoadingUser.value = false;
    }

    // Setup Sentry for tracking the user
    const { email } = profile.value;
    window.Sentry?.setUser({ id: userId.value, email });

    document.querySelector('meta[name="data-user-id"]').setAttribute('content', userId.value);
    document.querySelector('meta[name="data-user-token"]').setAttribute('content', userToken.value);
    document.querySelector('meta[name="data-user-staff"]').setAttribute('content', isStaff.value);
    isLoadingUser.value = false;
  }

  function logout() {
    currentTeamId.value = null;
    document.querySelector('meta[name="data-user-id"]').setAttribute('content', '');
    document.querySelector('meta[name="data-user-token"]').setAttribute('content', '');
    document.querySelector('meta[name="data-user-staff"]').setAttribute('content', '');
    window.location.href = `https://user.${BASE_DOMAIN}/logout?returnUrl=https://user.${BASE_DOMAIN}/auth/auth0?returnUrl=${window.location.origin}`;
  }

  return {
    currentTeamId,
    currentTeam,
    user,
    userId,
    profile,
    teamsById,
    teamMembersById,
    teamMembersMeta,
    getAuth,
    getTeamMembers,
    isFetchingTeamMembers,
    isAuthenticated,
    isStaff,
    isDesigner, // Group for peeps who can manage presets
    isLoadingUser, // Used to show a loading animation while the user is being loaded
    loadUser,
    setCurrentTeamId,
    planData,
    planPermissionData,
    isFetchingPermissions,
    hasPlan,
    isFreeAccountNoTeam,
    invitesData,
    isFetchingInvites,
    fetchInvites,
    getTeams,
    checkPlanPermissions,
    logout,
  };
});
